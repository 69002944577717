import * as React from 'react'
import LayoutWork from '../../components/layoutWork'
import {Container} from "react-bootstrap" 
import head from '../../images/portfolio/beyond/beyond-advertising-website-video.mp4'
import page1 from '../../images/portfolio/beyond/beyond-overview.png'
import page2 from '../../images/portfolio/beyond/beyond-incomming-message.png'
import { Link } from 'gatsby'

const PortfolioAdvPage = () => {
  return (
    <LayoutWork pageTitle="Beyond Advertising">
      <Container><p className='info'>Web application | For | <Link to='https://www.wdka.nl/programmes/advertising'>Willem de Kooning Academy</Link></p></Container><hr/>
      <Container>
        <br/>
        <h1>Beyond Advertising</h1>
        <h2>Discover the collection of WdKA's third year Advertising students (2020) ready for a internship during the pandemic.</h2>
        <br/>
        <video src={head} width="100%" height="auto" autoplay="true" loop><track kind="captions" srcLang="en" label="English" default /></video>
        <br/>
        <br/>
        <p>The project showcases the impressive work of WdKA's third-year Advertising students from 2020, who are actively seeking internship opportunities during the pandemic. This group of talented individuals embodies a new generation of creatives who are determined to drive change and evolution within the advertising industry.</p>
        <br/>
        <p>The students share a common belief that the traditional methods of advertising are outdated, and that it's time to embrace new approaches that prioritize creativity as a tool for building a better future and a better planet. They aspire to create innovative concepts that can address a wide range of challenges and promote critical thinking in response to the information we receive.</p>
        <img src={page1} alt="overview of students"/>
        <br/>
        <br/>
        <p>Their portfolio displays an array of cutting-edge projects that showcase their exceptional skills in creative ideation, copywriting, graphic design, and visual communication. Each project reflects their commitment to developing new and innovative ideas that can make a positive impact on society.</p>
        <img src={page2} alt="page of one student"/>
      </Container>
    </LayoutWork>
    
  )
}
export default PortfolioAdvPage