import * as React from 'react'
import "../components/style.scss"
import { Link } from 'gatsby'
import { Container } from 'react-bootstrap'
import Navbar from './navBar/navBar'
import Footer from './footer/footer'

const LayoutWork = ({ pageTitle, children }) => {
  return (
    <div>
    <title>{pageTitle} | Studio Coat</title>
    <Navbar />
    <hr/>
    <Link to='/work'><Container><p>← Back</p></Container></Link>
    <hr/>
    <main style={{
      minHeight: '77vh',
    }}>
      {children}
    </main>
    <Footer />
  </div>
  )
}
export default LayoutWork